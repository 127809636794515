<template>
  <div class="he-hander" :style="style">
    <div class="top" id="hander">
      <div class="left" @click="toHome" >
        <img id="hander-img"
          src="../views/static/img/维度logo.png"
          alt=""
        />
      </div>
      <div class="right">
        <div
          class="right-item"
          v-for="(item, index) in topList"
          :key="item.id"
          @click="toNav(item)"
        >
          <span class="span1" v-if="!item.hover" :style="fontStyle" :class="topIndex == index ? 'activeTop' : ''"
            >{{ item.name }}
          </span>
            <el-popover
            v-if="item.hover"
            placement="bottom"
            width="200"
            trigger="hover"
            :visible-arrow="false"
            popper-class="ppppp"
          >
            <div class="gywm-box">
              <p
                v-for="val in gywmList"
                class="p-item"
                @click="toPage(val)"
                :key="val.id"
              >
                {{ val.title }}
              </p>
            </div>
            <p
             
              slot="reference"
              class="p1"
              v-if="item.hover"
            >
              <span :style="fontStyle" :class="topIndex == index ? 'activeTop' : ''" class="span1">{{ item.name }} <i v-if="item.hover" class="el-icon-arrow-down"></i></span>
              
            </p>
          </el-popover>
        </div>
        <!-- <i class="el-icon-search" @click="searchDis"></i> -->
      </div>
      <div class="search" id="search">
        <el-input v-model="searchValue" placeholder="搜索" @keyup.enter.native="searchAllCompany"></el-input>
      </div>
    </div>
    <div class="fixed-bottom">
     <text v-if="is_text">COPYRIGHT (©) 2022 mo005_12446视频集合类网站.</text> 
      <div class="toTop" v-if="is_toTop"> 
        <!-- <i class="iconfont icon-xinlangweibo"></i>
        <i class="iconfont icon-shouji"></i> -->
        <i class="el-icon-arrow-up"  @click="toTop" v-if="scrollTop>0"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
    },
    topIndex: {
      type: [String, Number],
      default: 0,
    },
    is_toTop:{
      type:Boolean,
      default:true
    },
    is_text:{
      type:Boolean,
      default:true
    }
  },
  name: "he-top",
  components: {},

  data() {
    return { 
      fontSize:15,
      searchValue:'',
      is_search:false,
      tabIndex: 0,
      scrollTop:0,
      topList: [
        { id: 1, name: "首页", url: "/" },
        { id: 2, name: "3D模型库", url: "/3D" },
        { id: 3, name: "DCI版权登记", url: "/banquan" },
        // { id: 4, name: "咨询中心", url: "/journal" },
        // { id: 5, name: "关于", url: "",hover:true },
        // { id: 6, name: "联系", url: "/contact" },
      ],
       gywmList: [
        { id: 1, title: "关于我们", url: "/about/aboutWe" },
        { id: 2, title: "团队成员", url: "/about/aboutTeam" },
        { id: 3, title: "视频演示", url: "/about/aboutVideo" },
        { id: 4, title: "合作伙伴", url: "/about/aboutPartner" },
        { id: 5, title: "订阅内容", url: "/about/aboutSubscribe" },
      ],
    };
  },
  computed: {
    style() {
      return {
        background: this.background,
      };
    },
    fontStyle(){
      return{
        fontSize:this.fontSize+'px'
      }
    }
  },

  methods: {
    toHome(){
      this.$router.push({
        path: "/",
       
      })
    },  
    searchDis(){
     let search = document.getElementById("search");
     if(search.offsetHeight==0){
       search.style=`height:40px;transition: all 0.36s ease;`
     }else{
       search.style=`height:0px;transition: all 0.36s ease;`
     }
     
     
    },
    searchAllCompany(){
      console.log('chuafa   ')
      this.$router.push('/search/search')
    },
    toNav(item) {
      if (item.url) {
        this.$router.push(item.url);
      }
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
     // 监听页面滚动 获取滚动条位置
    windowScroll() {
      let clientHeight = document.body.clientHeight;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      let hander = document.getElementById("hander");
      let handerImg = document.getElementById("hander-img");
      // let search = document.getElementById("search");
      //   let ppppp = document.getElementsByClassName("ppppp")[0];
      if (this.scrollTop > 0) {
        hander.style = `height:70px;transition: all 0.36s ease;background:#131313`;
        handerImg.style=`height:64px;transition: all 0.36s ease;`
        // ppppp.style=`transform: translateY(15px);display:none`
        // search.style=`top:70px;transition: all 0.36s ease;`
        this.fontSize=14
      } else {
        hander.style = `height:80px;transition: all 0.36s ease;`;
         handerImg.style=`height:74px;transition: all 0.36s ease;`
        //  ppppp.style=`transform: translateY(20px);display:none`
        //   search.style=`top:80px;transition: all 0.36s ease;`
           this.fontSize=15
      }
    },
    toPage(item){
        if(item.url){
          this.$router.push(item.url)
        }
    }
  },
  watch: {},
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
};
</script>
<style lang='less' scoped>
.ppppp{
  color: #555;
}
.gywm-box {
  background: #323232;
  position: relative;
  transition: all 0.36s ease;
 .p-item {
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.36s ease;
  }
  .p-item:hover {
    background: #262626;
    color: #fff;
    transition: all 0.36s ease;
  }
}

.he-hander {
  .top {
    min-width: 1050px;
    padding: 0px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 80px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    .left {
      cursor: pointer;
      img {
        height: 64px;
        // width: 193px;
        transition: all 0.36s ease;
      }
    }
    .right {
      display: flex;
      .right-item {
        margin-left: 60px;
        cursor: pointer;
        .span1 {
          font-size: 16px;
          transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          color: rgba(255, 255, 255, 0.7);
          position: relative;
        }
        .activeTop {
          color: #fff;
        }
        .span1:hover {
          color: #fff;
           transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
         .span1:hover i{
          color: #fff;
           transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
        .span1::before {
          position: absolute;
          top: 50%;
          left: -15px;
          display: inline-block;
          width: 0px;
          height: 1px;
          content: "";
          transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          transform: translateY(-2px);
          opacity: 0;
          background: rgba(255, 255, 255, 0.7);
        }
        .span1::after {
          position: absolute;
          top: 50%;
          right: -15px;
          display: inline-block;
          width: 0;
          height: 1px;
          content: "";
          transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          transform: translateY(-2px);
          opacity: 0;
          background: rgba(255, 255, 255, 0.7);
        }
        .span1:hover::after {
          width: 10px;
          opacity: 1;
        }
        .span1:hover::before {
          width: 10px;
          opacity: 1;
        }
        i{
          color: rgba(255, 255, 255, 0.7);
          margin-left: 10px;
          font-size: 12px;
        }
      }
      .el-icon-search{
     color: rgba(255, 255, 255, 0.7);
     margin-left: 50px;
     cursor: pointer;
     font-weight: bold;
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }
      .el-icon-search:hover {
        color: #fff;
         transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }
    }
    .search{
      position: absolute;
      height: 0px;
      overflow: hidden;
      right: 30px;
      top: 80px;
      transition: all 0.36s ease;
       /deep/ .el-input__inner {
        background: #313131;
        border: 1px solid rgba(115, 115, 115, 0.21);
        border-radius: 0px;
        width: 268px;
      }
    }
  }
  .fixed-bottom {
    position: fixed;
    right: 60px;
    bottom: 20px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    z-index: 1111;
    display: flex;
    .toTop {
      display: flex;
      margin-left: 50px;
      i {
        font-size: 18px;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        width: 50px;
        // border-right: 1px solid rgba(255, 255, 255, 0.5);
        cursor: pointer;
         transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }
      i:nth-child(2) {
        border: none;
      }
      i:nth-child(3) {
        border: none;
      }
      i:hover{
        color: #fff;
         transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }
    }
  }
}
</style>
