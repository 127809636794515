import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import './views/static/iconfont/iconfont.css';
import './views/static/css/index.css'
import 'animate.css';
// // px2rem 自适应
//适配
import "./components/lib-flexible/flexible.js";
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
