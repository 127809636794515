import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 客户团队
    teamId:'1522824467978842113', 
    // 本地叶萌团队
    // teamId:'1493881825404403713'
      // 生产叶萌团队
      // teamId:'1522499883375165442'
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
