<template>
<!-- 滚动时间 -->
  <div class="home" ref="home"  @wheel="handleWheel">
    <heHander :is_toTop="false"></heHander>
    <el-carousel :height="height" ref="carousel" @change="carouselChange" direction="vertical" :autoplay="false"
      trigger="click" arrow="always" >
      <!-- indicator-position="none" -->
      <el-carousel-item class="el-carousel-item1">
        <div class="banner-box">
          <el-carousel trigger="click" @change="changeBanner" ref="bannerRef" :autoplay="true" :interval="4000"
            indicator-position="none" arrow="never">
            <el-carousel-item v-for="item in bannerImg" indicator-position="none" :key="item.id">
              <video :controls="true" v-if="item.type == 'video'" preload muted :autoplay="false" :class="
                carouselIndex == 0
                  ? 'animate__animated animate__slideInUp'
                  : ''
              ">
                <source :src="item.img" type="video/mp4" />
              </video>
              <img v-else :src="item.img" alt="" class="bannerImg" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="banner-bottom2">
          <div class="left-arow" @click="$refs.bannerRef.prev()">PREV</div>
          <div class="quan-list">
            <div class="quan-f" v-for="(item, index) in bannerImg" @click="clickBanner(index)" :key="item.id">
              <div class="quan" :class="index == bannerIndex ? 'activeBanner' : ''"></div>
            </div>
          </div>
          <div class="right-arow" @click="$refs.bannerRef.next()">NEXT</div>
        </div>
        <!-- <div class="right-num">
          <div class="num-t">0{{ bannerIndex + 1 }}</div>
          <div class="num-xian"></div>
          <div class="num-b">04</div>
        </div> -->
      </el-carousel-item>
      <el-carousel-item class="el-carousel-item2">
        <h1>3D模型库</h1>
        <div class="banner-box" :class="
          carouselIndex == 1 ? 'animate__animated animate__slideInUp' : ''
        ">
          <div class="banner-list" id="banner-list">
            <div class="banner-item" v-for="item in modeList" :key="item.id" @click="tovideoDetail(item)">
              <img :src="item.thumbnail" alt="" />
              <h1>{{ item.modelName }}</h1>
              <p>
                {{ item.categoryName_f ? item.categoryName_f : "暂无分类" }}
              </p>
            </div>
          </div>
        </div>
        <div class="banner-bottom">
          <div class="bottom-prev el-icon-back" @click="clickPrev"></div>
          <div class="center">
            <div class="center-box" id="center-box">
              <div class="center-item" @click="toSwitch(ind)" v-for="(item, ind) in modeList" :key="item.id">
                <span :class="index == ind ? 'activeSpan' : ''"></span>
              </div>
            </div>
          </div>
          <div class="bottom-next el-icon-right" @click="clickNext"></div>
        </div>
      </el-carousel-item>
      <el-carousel-item class="el-carousel-item3">
        <div class="item3-content" :class="
          carouselIndex == 2 ? 'animate__animated animate__slideInUp' : ''
        ">
          <div class="left"><img src="" alt="" /></div>
          <div class="right">
            <h2>非物质文化遗产 / Intangible Cultural Heritage</h2>
            <p>
              “非遗”是先辈通过日常生活的运用而留存到现代的文化财富。在历史的长河中自然生成又不断发展流变，是以人为本的活态文化遗产，强调以人为核心的技艺、经验、精神！
              科学与艺术总在山顶重逢，借助现代科技我们制作了“非物质文化遗产3D数字展”，希望能够以此增进对我国非物质文化遗产的了解，推进我国非物质文化遗产的传承与保护。让我们走近大师、走近艺术！
            </p>
            <div class="more" @click="toVideo">MORE</div>
            <div class="more-icon el-icon-right" @click="toVideo"></div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item class="el-carousel-item4">
        <div class="item4-box" @click="toServiceDetail" :class="
          carouselIndex == 3 ? 'animate__animated animate__slideInUp' : ''
        ">
          <div class="item4-top">
            <h2>解决方案</h2>
            <!-- <h1>SERVICE</h1> -->
          </div>
          <div class="svg-box">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" width="720px" height="560px" viewBox="0 0 720 560"
              enable-background="images/8f256fe34b6542af9d486dcb4a025f9c.gif" xml:space="preserve">
              <defs>
                <clipPath id="svg1">
                  <path id="path1" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#C9C9C9"
                    stroke-miterlimit="10"
                    d="M686.896,236.465  c-43.759,79.561-85.528,118.845-103.927,184.481c-15.415,48.233-14.42,107.407-141.718,91.494  c-106.911-16.409-186.968-27.349-268.518-7.459c-26.52,7.459-124.645-1.657-85.528-104.423  c8.122-36.465,43.759-79.561,14.917-248.627C96.156,124.582,96.156,0.766,257.266,37.065c50.72,9.945,168.21,43.089,280.453,27.846  C645.794,43.479,736.123,112.151,686.896,236.465z" />
                </clipPath>
              </defs>
              <path class="bgPath" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#C9C9C9"
                stroke-miterlimit="10"
                d="M676.268,233.864 c-7.626,29.762-23.313,95.019,21.138,184.223c18.12,33.219,18.66,141.521-61.405,128.854c-215.744-41.72-304.9,0.267-371.465,11.073 c-30.703,1.51-94.124,11.074-116.774-82.548c-23.658-67.95-25.67-84.058-110.735-203.349 C1.311,213.256-21.932,32.978,149.774,42.595c33.219,1.51,142.444,32.717,341.264-27.18 C607.389-20.945,728.612,21.456,676.268,233.864z" />

              <image class="suc_img"
                xlink:href="https://resources.jsmo.xin/templates/upload/12446/201906/1559627614984.jpg" x="0" y="0"
                height="560px" width="720px" clip-path="url(#svg1)" />
              <image class="first_img" xlink:href="./test-img/black1.jpg" x="0" y="0" height="560px" width="720px"
                clip-path="url(#svg1)" />
            </svg>
            <div class="svg-text">
              <h1>多平台3D可视化互动体验 扫码分享&一键转发</h1>
              <h2>
                3D大屏互动<br />
                3D互动可在大屏幕进行完美展示，会议室投影、展馆互动屏、商场大屏幕......瞬间让您的展示成为焦点，支持本地离线部署，无网络也可进行互动展示。
              </h2>
            </div>
            <!-- <div class="right-num">01.</div> -->
            <!-- <div class="svg-bottom">
              <i class="el-icon-back"></i>
              <i class="el-icon-right" @click="tabSvg"></i>
            </div> -->
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item class="el-carousel-item5">
        <h1 :class="
          carouselIndex == 4 ? 'animate__animated animate__slideInUp' : ''
        ">
          合作模式
        </h1>
        <img :class="
          carouselIndex == 4 ? 'animate__animated animate__slideInUp' : ''
        " src="../views/static/img/合作模式底图.png" alt="" />
        <!-- <el-carousel
          height="740px"
          :autoplay="false"
          arrow="never"
          indicator-position="none"
          ref="item5Banner"
          :class="carouselIndex==4?'animate__animated animate__slideInUp':''"
        >
          <el-carousel-item
            v-for="item in soliverLise"
            :key="item.id"
            class="item5-banner"
           
          >
            <div class="leftbox" @click="toTeamDetail">
              <div class="title">
                {{ item.title }}<span>/ {{ item.title_s }}</span>
              </div>

              <p>
                {{ item.text }}
              </p>
              <div class="more">
                <div class="more-left">MORE</div>
                <div class="more-right">
                  <i class="el-icon-right i1"></i
                  ><i class="el-icon-right i2"></i>
                </div>
              </div>
            </div>

            <img class="item5-img" @click="toTeamDetail"  :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="banner-bottom">
          <div class="center">
            <div class="center-box" id="center-box">
              <div
                class="center-item"
                @click="tabBanner(ind)"
                v-for="(item, ind) in soliverLise"
                :key="item.id"
              >
                <span :class="item5Index == ind ? 'activeSpan' : ''"></span>
              </div>
            </div>
            <div class="text-box">
              <div class="left-arow"><i class="el-icon-back"></i></div>
              <div
                class="text-item"
                v-for="(item, ind) in soliverLise"
                :key="item.id"
                @click="tabBanner(ind)"
              >
                <div
                  class="text-title"
                  :class="item5Index == ind ? 'Activetitle' : ''"
                >
                  {{ item.title }}
                </div>
                <div
                  class="text-num"
                  :class="item5Index == ind ? 'Activenum' : ''"
                >
                  {{ item.num }}
                </div>
              </div>
              <div class="right-arow"><i class="el-icon-right"></i></div>
            </div>
          </div>
        </div> -->
      </el-carousel-item>
      <el-carousel-item class="el-carousel-item6">
        <div class="item6-box" :class="
          carouselIndex == 5 ? 'animate__animated animate__slideInUp' : ''
        ">
          <div class="left">
            <h1>维度 三维互动展示平台</h1>

            <p>
              <span>地址：广州市番禺区为民西路3号</span>
              <span>手机（微信）：韩先生15728736071 </span>
              <span>邮箱：gzwatt@139.com</span>
            </p>
            <div class="title_C">
              <span @click="toBqsm">版权声明</span> <span>隐私政策</span>
              <span>服务条款</span> <span>加入我们</span>
            </div>
            <!-- <div class="icon-box">
              <i class="iconfont icon-xinlangweibo"></i>
              <i class="iconfont icon-QQ"></i>
              <i class="iconfont icon-weixin"></i>
            </div> -->
          </div>
          <!-- <div class="center">
            <h2>BOBRO视频工作室</h2>
            <p>地址：北京市朝阳区世贸天阶8888号</p>
            <p>邮编：100000</p>
            <p>电话：010-66806660</p>
            <p>手机：18666889966</p>
            <p>传真：000-66668888</p>
            <p>邮箱：bobro@studio.com</p>
          </div>
          <div class="right">
            <el-input placeholder="姓名"></el-input>
            <el-input placeholder="邮箱"></el-input>
            <el-input placeholder="电话"></el-input>
            <el-input type="textarea" placeholder="内容"></el-input>
            <div class="right-button">提交</div>
          </div> -->
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import heHander from "@/components/he-hander.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    heHander,
  },
  data() {
    return {
      page: {
        current: 1,
        size: 100,
        teamId: "1522824467978842113",
      },
      modeList: [],
      soliverLise: [
        {
          id: 1,
          num: "01",
          img: "https://resources.jsmo.xin/templates/upload/12446/201903/1553577407117.jpg",
          title: "S.Oliver",
          title_s: "资深视频剪辑师",
          text: "国际化的摄影风格，受国内一线时尚媒体和明星追捧，并与许多国际品牌建立深度合作。对时尚摄影圈的影响力不可估量。尹超早期被媒体称为“受心灵指引的摄影师...",
        },
        {
          id: 2,
          num: "02",
          img: "https://resources.jsmo.xin/templates/upload/12446/201810/1540277043945.jpg",
          title: "無象AOE",
          title_s: "资深摄影师",
          text: "中国时尚摄影师、视觉艺术家，毕业于中央美术学院。2003年开始为《青年视觉》杂志拍摄封面；之后，凭借一系列为《青年视觉》拍摄的作品在时尚圈崭露头角在北京今日美术馆推出个人摄影装置展；“视觉艺术展”在上海当代艺术馆展出 ...",
        },
      ],
      index: 0,
      item5Index: 0,
      flg: true,
      height: "100vh",
      list: [],
      imgItem: {},
      imgInd: 0,
      leftImg: "",
      rightImg: "",
      background: "",
      tabName: "",
      bannerIndex: 0,
      bannerIndex2: 0,
      carouselIndex: 0,
      isBottom: false,
      is_img: false,
      bannerImg: [
        // {
        //   id: 1,
        //   img: require("./static/video/1、先临视频.mp4"),
        //   type: "video",
        // },
        { id: 2, img: require("./test-img/banner2.jpg") },
        { id: 3, img: require("./test-img/banner3.jpg") },
      ],
      imgList: [
        { id: 1, img: require("./test-img/banner2.jpg") },
        { id: 2, img: require("./test-img/banner3.jpg") },

        { id: 5, img: require("./test-img/banner2.jpg") },
        { id: 6, img: require("./test-img/banner3.jpg") },
      ],
    };
  },
  computed: {},
  methods: {
    toVideo() {
      this.$router.push({
        path: "/3D",
        query: { //query是个配置项
          event: 'heritage'
        }
      })
    },
    toBqsm() {
      // this.$router.push("/copyright/copyright");
      const { href } = this.$router.resolve({
        name: "copyright",
      });
      window.open(href, '_blank');
    },
    tabBanner(ind) {
      this.item5Index = ind;
      this.$refs.item5Banner.setActiveItem(ind);
    },
    tovideoDetail(item) {
      this.$router.push("/3D/3DDetail");
      sessionStorage.setItem("modeList", JSON.stringify(item));
    },
    toServiceDetail() {
      // this.$router.push("/service/serviceDetail");
    },
    toTeamDetail() {
      this.$router.push("/about/aboutTeamDetail");
    },
    tabSvg() { },
    getpage() {
      axios
        .get("/shoplink/api/model/page", {
          params: this.page,
        })
        .then(
          (res) => {
            //访问成功时调用，response.data代表返回成功时的数据
            this.modeList = res.data.data.records;
            this.page.total = res.data.data.total;
            this.page.current = res.data.data.current;
            this.modeList.forEach((item) => {
              axios.get("/shoplink/api/category/" + item.categoryId).then(
                (res) => {
                  //访问成功时调用，response.data代表返回成功时的数据
                  if (res.data.data) {
                    item.categoryName_f = res.data.data.categoryName;
                  }
                },
                (error) => {
                  //访问失败时调用，error.message可以表示返回失败时的信息
                }
              );
            });

            let dom_f = document.getElementById("banner-list");
            // console.log("dom_f", dom_f);
            dom_f.style = `width:${this.modeList.length * 680}px`;
            let centerDom = document.getElementById("center-box");
            centerDom.style = `width:${this.modeList.length * 680}px`;
          },
          (error) => {
            //访问失败时调用，error.message可以表示返回失败时的信息
          }
        );
    },
    //   点击小圆圈
    toSwitch(index) {
      this.index = index;
      let dom_f = document.getElementById("banner-list");
      let centerDom = document.getElementById("center-box");

      let dom_width =
        document.getElementsByClassName("banner-item")[0].offsetWidth;
      dom_f.style = `transform: translateX(-${index * dom_width}px);width:${this.modeList.length * 680
        }px;  transition: 1s;`;
      centerDom.style = `transform: translateX(-${index * dom_width
        }px); width:${this.modeList.length * 680}px; transition: 1s;`;
    },
    clickPrev() {
      if (this.index == 0) {
        return;
      }
      this.index--;
      let dom_f = document.getElementById("banner-list");
      let centerDom = document.getElementById("center-box");

      let dom_width =
        document.getElementsByClassName("banner-item")[0].offsetWidth;
      // dom_f.style.left=-(this.index*dom_width)+100 + 'px'
      dom_f.style = `transform: translateX(-${this.index * dom_width
        }px); width:${this.modeList.length * 680}px; transition: 1s;`;
      centerDom.style = `transform: translateX(-${this.index * dom_width
        }px); width:${this.modeList.length * 680}px; transition: 1s;`;
    },
    clickNext() {
      if (this.index == this.imgList.length - 2) {
        return;
      }
      this.index++;
      let dom_f = document.getElementById("banner-list");
      let centerDom = document.getElementById("center-box");

      let dom_width =
        document.getElementsByClassName("banner-item")[0].offsetWidth;
      // dom_f.style.left=-(this.index*dom_width)+100 + 'px'
      dom_f.style = `transform: translateX(-${this.index * dom_width
        }px); width:${this.modeList.length * 680}px; transition: 1s;`;
      centerDom.style = `transform: translateX(-${this.index * dom_width
        }px);width:${this.modeList.length * 680}px;  transition: 1s;`;
    },

    // 第一个轮播图
    changeBanner(e) {
      this.bannerIndex = e;
      this.is_img = false;
    },
    clickBanner(index) {
      this.$refs.bannerRef.setActiveItem(index);
      this.bannerIndex = index;
    },

    carouselChange(e) {
      if (e > 0) {
        this.background = "#fff";
      } else {
        this.background = "";
      }
      this.carouselIndex = e;
    },
    //节流函数
    throttle(fn, gapTime) {
      let _this = this;
      return function () {
        let _nowTime = +new Date();
        if (_nowTime - _this._lastTime > gapTime || !_this._lastTime) {
          fn(...arguments); // 函数可以带参数
          _this._lastTime = _nowTime;
        }
      };
    },
    handleWheel(e) {
      e.stopPropagation();
      this.throttle(this.pageUpOrDown, 600)(e);
    },
    pageUpOrDown(e) {
      if (e.deltaY > 0) {
        //向下
        if (this.carouselIndex == 5) {
          this.isBottom = true;

          return;
        }

        this.$refs.carousel.next();

        console.log("正在向下");
      } else if (e.deltaY < 0) {
        //向上
        this.isBottom = false;
        console.log("正在向上");
        if (this.carouselIndex == 0) {
          return;
        }
        this.$refs.carousel.prev();
      }
    },
  },
  mounted() {
    this.getpage();
    console.log(this.$route.query);
    if (this.$route.query && this.$route.query.index) {
      setTimeout(() => {
        this.tab({}, this.$route.query.index, this.$route.query.color);
      });
    }
  },
  scrollToBottom: function () { },
};
</script>
<style lang='less' scoped>
/deep/.el-carousel__button{
  width:10px !important;
  height:30px !important;
  border-radius:10px
}
.home {
  .el-carousel-item1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    // background: url("./static/img/banner_bg.png") no-repeat 0 0 /100% 100%;
    // padding-top: 8.75rem;
    box-sizing: border-box;

    .banner-box {
      // padding-top: 10px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: #242424;

      /deep/ .el-carousel {
        height: 100%;
        width: 100%;
      }

      /deep/ .el-carousel__container {
        height: 100%;
        width: 100%;
      }

      /deep/ .el-carousel-item {}

      video {
        padding-top: 30px;
        width: 100%;
        height: 90%;
      }
    }

    .banner-bottom2 {
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translate(-50%, 50%);
      display: flex;
      z-index: 111;
      align-items: center;

      .left-arow {
        color: #fff;
        opacity: 0.5;
        font-size: 14px;
        cursor: pointer;
      }

      .left-arow:hover {
        opacity: 1;
        transition: 1s;
      }

      .right-arow {
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        opacity: 0.5;
      }

      .right-arow:hover {
        opacity: 1;
        transition: 1s;
      }

      .quan-list:hover {
        width: 240px;
        transition: 1s;
        cursor: pointer;
      }

      .quan-list {
        display: flex;
        width: 220px;
        transition: 1s;
        margin: 0px 50px;
        height: 50px;

        .quan-f {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .quan {
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 1);
          opacity: 0.5;
          cursor: pointer;
          transition: 1s;
        }

        .activeBanner {
          //   transition: 1s;
          opacity: 1;
        }
      }
    }

    .right-num {
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 111;

      .num-t {
        font-size: 20px;
        color: #fff;
        text-align: center;
        padding-bottom: 28px;
      }

      .num-xian {
        height: 3px;
        background: #fff;
      }

      .num-b {
        font-size: 14px;
        color: #636363;
        text-align: center;
        padding-top: 28px;
      }
    }
  }

  .el-carousel-item2 {
    background: #202020;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 32px;
      color: #fff;
      padding-left: 100px;
      margin-bottom: 50px;
    }

    .banner-box {
      height: 520px;
      padding-left: 100px;
      width: 100%;
      //   background: #fff;
      position: relative;

      .banner-list {
        position: absolute;
        width: 6800px;
        height: 400px;

        .banner-item {
          display: inline-block;
          padding-right: 20px;
          width: 620px;
          height: 100%;
          box-sizing: border-box;
          text-align: center;

          img {
            max-width: 100%;
            max-height: 100%;
            // height: 400px;
          }

          h1 {
            font-size: 18px;
            color: #fff;
            text-align: center;
            padding-left: 20px;
            padding-top: 24px;
            padding-bottom: 10px;
          }

          p {
            color: #888;
            font-size: 13px;
            text-align: center;
            padding-left: 20px;
            padding-bottom: 24px;
          }
        }
      }
    }

    .banner-bottom {
      padding: 50px;
      z-index: 1111;
      display: flex;

      .bottom-prev {
        color: #fff;
        opacity: 0.5;
        font-size: 20px;
        cursor: pointer;
      }

      .center {
        margin: 0px 30px;
        flex: 1;
        overflow: hidden;

        .center-box {
          width: 6800px;
        }

        // background: #fff;
        .center-item {
          display: inline-block;
          width: 620px;
          text-align: center;

          span {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #fff;
            opacity: 0.5;
            position: relative;
            cursor: pointer;
            padding: 9px;
            border: 2px solid #202020;
            background-origin: content-box;
            background-clip: content-box;
          }

          span:hover {
            opacity: 1;
          }

          .activeSpan {
            padding: 9px;
            border: 2px solid #fff;
            background-origin: content-box;
            background-clip: content-box;
            opacity: 1;
          }

          span:after {
            content: "";
            width: 300px;
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: 40px;
          }

          span:before {
            content: "";
            width: 300px;
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: -318px;
          }
        }
      }

      .bottom-next {
        color: #fff;
        opacity: 0.5;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }

  .el-carousel-item3 {
    display: flex;
    align-items: center;
    background: #131313;

    .item3-content {
      display: flex;

      .left {
        background: url("../views/static/img/image1.jpg");
        transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        transform: translateX(60px);
        border-radius: 220vw 260vw 200vw 200vw;
        width: 39vw;
        height: 459px;
        background-size: cover;
      }

      .left:hover {
        border-radius: 260vw 200vw 200vw 230vw;
      }

      .right {
        max-width: 700px;
        margin-left: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          color: rgb(255, 255, 255);
          font-size: 18px;
          margin-bottom: 50px;
        }

        p {
          color: #fff;
          opacity: 0.5;
          line-height: 30px;
        }

        .more {
          color: rgba(255, 255, 255, 0.8);
          // opacity: 0.5;
          border-bottom: 2px solid #fff;
          line-height: 40px;
          width: 200px;
          cursor: pointer;
          margin-bottom: 5px;
          transition: 1s;
        }

        .more:hover {
          color: #fff;
          transition: 1s;
        }

        .more-icon {
          text-align: right;
          color: #fff;
          font-size: 18px;
          width: 200px;
          transform: translateY(10px);
          transition: 1s;
          opacity: 0;
        }

        .more:hover+.more-icon {
          transform: translateY(0px);
          transition: 1s;
          opacity: 1;
        }
      }
    }
  }

  .el-carousel-item4 {
    background: #202020;
    display: flex;
    align-items: center;

    // justify-content: center;
    .item4-box {
      height: 640px;
      display: flex;

      .item4-top {
        margin-left: 100px;
        margin-right: 600px;

        h2 {
          font-size: 24px;
          line-height: 30px;
          color: #f0f0f0;
        }

        h1 {
          font-size: 48px;
          line-height: 48px;
          display: block;
          color: #f0f0f0;
        }
      }

      .svg-box {
        cursor: pointer;
        position: relative;

        #svg1 path {
          animation: svgvs 5s infinite alternate;
        }

        @keyframes svgvs {
          0% {
            d: path("M686.896,236.465  c-43.759,79.561-85.528,118.845-103.927,184.481c-15.415,48.233-14.42,107.407-141.718,91.494  c-106.911-16.409-186.968-27.349-268.518-7.459c-26.52,7.459-124.645-1.657-85.528-104.423  c8.122-36.465,43.759-79.561,14.917-248.627C96.156,124.582,96.156,0.766,257.266,37.065c50.72,9.945,168.21,43.089,280.453,27.846  C645.794,43.479,736.123,112.151,686.896,236.465z"
              );
          }

          100% {
            d: path("M686.309,240.006 c-36.931,76.881-48.772,67.185-74.152,184.634c-15.43,48.274,17.417,155.272-171.697,111.48 c-51.26-15.432-133.374-69.18-268.739-27.373c-26.542,7.462-134.702-1.66-95.552-104.512c8.128-37.491,32.846-79.628,14.93-189.114 C80.149,126.039,76.168,3.116,256.323,40.441c121.431,35.832,147.448,72.985,290.637,37.822 C655.126,56.813,752.497,116.583,686.309,240.006z"
              );
          }
        }

        .bgPath {
          animation: svgvs2 5s infinite alternate;
          // 背景色
          fill: #2b2b2b;
          stroke: #2b2b2b;
        }

        @keyframes svgvs2 {
          0% {
            d: path("M676.268,233.864 c-7.626,29.762-23.313,95.019,21.138,184.223c18.12,33.219,18.66,141.521-61.405,128.854c-215.744-41.72-304.9,0.267-371.465,11.073 c-30.703,1.51-94.124,11.074-116.774-82.548c-23.658-67.95-25.67-84.058-110.735-203.349 C1.311,213.256-21.932,32.978,149.774,42.595c33.219,1.51,142.444,32.717,341.264-27.18 C607.389-20.945,728.612,21.456,676.268,233.864z"
              );
          }

          100% {
            d: path("M680.177,229.565 c-7.569,29.547-44.128,94.331,7.997,182.89c36.977,43.973,8.53,150.492-70.959,137.916c-238.167-33.423-211.747-19.723-358.782,1 c-83.45,20.486-113.431,8.994-135.917-83.95c-22.487-111.431-5.497-81.45-89.946-199.879C-2.887,209.106-5.973,20.14,164.491,29.687 c32.979,1.499,151.407,46.473,287.825-0.998C567.826-7.409,757.13,4.703,680.177,229.565z"
              );
          }
        }

        .svg-text {
          position: absolute;
          top: 160px;
          left: -520px;
          transition: 1s;
          width: 455px;
          text-indent: 2em;

          h1 {
            font-size: 36px;
            font-weight: 900;
            line-height: 48px;
            color: #fff;
          }

          h2 {
            font-size: 16px;
            line-height: 34px;
            text-align: left;
            color: #fff;
          }
        }

        .right-num {
          font-size: 86px;
          font-weight: 900;
          line-height: 116px;
          position: absolute;
          right: -60px;
          top: 0px;
          color: #fff;
        }

        .svg-bottom {
          position: absolute;
          bottom: -20px;
          display: flex;
          width: 100%;
          justify-content: space-around;
          color: #fff;
          font-size: 20px;
        }
      }

      .svg-text {
        left: -140px;
        top: 240px;
        transition: 1s;
      }
    }
  }

  .el-carousel-item5 {
    background: #202020;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: #fff;

      font-size: 30px;
      margin-right: 100px;
      position: absolute;
      top: 150px;
      left: 100px;
    }

    .item5-banner {

      // position: relative;
      .leftbox {
        position: absolute;
        top: 50%;
        left: 200px;
        width: 615px;
        transform: translateY(-50%);

        .title {
          color: #fff;
          font-size: 18px;
          margin-bottom: 50px;

          span {
            font-size: 14px;
            color: #777;
          }
        }

        p {
          color: #868585;
          font-size: 13px;
          line-height: 26px;
        }

        .more {
          margin-top: 100px;
          border: none;
          border-top: 2px solid #fff;
          width: 195px;
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;

          .more-left {
            color: rgba(255, 255, 255, 0.7);
            transition: 1s;
          }

          .more-right {
            color: rgba(255, 255, 255, 0.7);
            width: 30px;
            display: flex;
            overflow: hidden;

            i {
              transition: 1s;
              //  margin-left: 10px;
            }

            .i1 {
              transform: translateX(-24px);
              opacity: 0;
            }
          }
        }

        .more:hover .more-left {
          transition: 1s;
          color: #fff;
        }

        .more:hover .i1 {
          transform: translateX(16px);
          transition: 1s;
          color: #fff;
          opacity: 1;
        }

        .more:hover .i2 {
          transform: translateX(16px);
          transition: 1s;
          color: #fff;
        }
      }

      .item5-img {
        // width: 100%;
        height: 100%;
        max-width: initial;
        width: auto;
      }
    }

    .banner-bottom {
      transform: translateY(-12px);
      position: relative;
      z-index: 11;
      z-index: 1111;
      display: flex;

      .center {
        // margin: 0px 30px;
        flex: 1;
        overflow: hidden;

        .center-box {
          width: 6800px;
        }

        // background: #fff;
        .center-item {
          display: inline-block;
          width: 480px;
          text-align: center;

          span {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: transparent;
            opacity: 0.5;
            position: relative;
            cursor: pointer;
            padding: 9px;
            border: 2px solid transparent;
            background-origin: content-box;
            background-clip: content-box;
          }

          span:hover {
            background-color: #fff;
            transition: 1s;
            opacity: 1;
          }

          .activeSpan {
            padding: 9px;
            border: 2px solid #fff;
            background-origin: content-box;
            background-clip: content-box;
            opacity: 1;
            background-color: #fff;
          }

          span:after {
            content: "";
            width: 200px;
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: 40px;
          }

          span:before {
            content: "";
            width: 200px;
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: -215px;
          }
        }

        .text-box {
          margin-top: 40px;
          display: flex;
          position: relative;

          .text-item:hover .text-title {
            color: #fff;
            opacity: 1;
            transition: all 0.3s ease-out 0s;
          }

          .text-item:hover .text-num {
            color: #fff;
            opacity: 1;
            transition: all 0.3s ease-out 0s;
          }

          .text-item {
            width: 480px;
            text-align: center;
            cursor: pointer;

            .text-title {
              font-size: 12px;
              color: #fff;
              opacity: 0.6;
              margin-bottom: 20px;
              transition: all 0.3s ease-out 0s;
            }

            .text-num {
              font-size: 14px;
              overflow: hidden;
              transition: all 0.3s ease-out 0s;
              text-align: center;
              color: #666;
            }

            .Activetitle {
              font-size: 18px;
              opacity: 1;
              color: #fff;
              margin-bottom: 20px;
            }

            .Activenum {
              font-size: 50px;
              color: #fff;
            }
          }

          .left-arow {
            font-size: 20px;
            position: absolute;
            top: 40px;
            left: 50px;
            color: rgba(255, 255, 255, 0.8);
          }

          .right-arow {
            font-size: 20px;
            top: 40px;
            position: absolute;
            right: 50px;
            color: rgba(255, 255, 255, 0.8);
            position: absolute;
          }
        }
      }
    }
  }

  .el-carousel-item6 {
    background: url("https://resources.jsmo.xin/templates/upload/12446/201906/1559713364700.jpg");
    background-position: initial;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    // align-items: center;
    justify-content: center;

    .item6-box {

      // display: flex;
      .left {
        max-width: 1200px;

        h1 {
          font-size: 32px;
          color: #fff;
          //  margin: 50px;
        }

        padding-top: 200px;

        .title_C {
          display: flex;
          // justify-content: space-around;

          color: #fff;
          font-size: 16px;
          margin-bottom: 10px;
          margin-top: 30px;

          span {
            margin-right: 100px;
            cursor: pointer;
          }
        }

        p {
          color: #fff;
          margin-top: 50px;
          //  margin: 50px;
          font-size: 16px;
          border-bottom: 1px solid;
          padding-bottom: 36px;

          span {
            margin-right: 70px;
          }
        }

        .icon-box {
          display: flex;
          color: #888;

          i:hover {
            background: #5c5c5c;
            color: #fff;
            transition: all 0.3s ease-out 0s;
          }

          i {
            transition: all 0.3s ease-out 0s;
            margin-right: 20px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .center {
        margin-right: 100px;

        h2 {
          font-size: 16px;
          margin-bottom: 25px;
          font-weight: 100;
          color: #fff;
        }

        p {
          color: rgba(255, 255, 255, 0.6);
          font-size: 13px;
          line-height: 23px;
        }
      }

      .right {
        width: 375px;

        /deep/ .el-input__inner {
          width: 100%;
          background: #1a1a1a;
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
          border-radius: 0px;
        }

        /deep/ .el-textarea__inner {
          height: 120px;
          font-size: 16px;
          width: 100%;
          background: #1a1a1a;
          resize: none;
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
          border-radius: 0px;
        }

        .right-button:hover {
          background: #7b7b7b;
          transition: all 0.3s ease-out 0s;
        }

        .right-button {
          cursor: pointer;
          transition: all 0.3s ease-out 0s;
          font-size: 15px;
          width: 150px;
          height: 42px;
          margin-top: 20px;
          border-radius: 30px;
          background: #5c5c5c;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.fixed-bottom {
  position: fixed;
  right: 60px;
  bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
  z-index: 1111;
}
.bannerImg{
  width: 1920px;
}
</style>
