import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/3D',
    name: '3D',
    component:()=> import('../views/video/video.vue')
  },
  {
    path: '/banquan',
    name: 'banquan',
    component:()=> import('../views/service/service.vue')//服务范围
  },
  {
    path: '/journal',
    name: 'journal',
    component:()=> import('../views/journal/journal.vue')//咨询中心
  },
  {
    path: '/contact',
    name: 'contact',
    component:()=> import('../views/contact/contact.vue')//咨询中心
  },
  {
    path: '/about/aboutWe',
    name: 'aboutWe',
    component:()=> import('../views/about/aboutWe.vue')//关于我们
  },
  {
    path: '/about/aboutTeam',
    name: 'aboutTeam',
    component:()=> import('../views/about/aboutTeam.vue')//团队成员
  },
  {
    path: '/about/aboutVideo',
    name: 'aboutVideo',
    component:()=> import('../views/about/aboutVideo.vue')//视频演示
  },
  {
    path: '/about/aboutPartner',
    name: 'aboutPartner',
    component:()=> import('../views/about/aboutPartner.vue')//合作伙伴
  },
  {
    path: '/about/aboutSubscribe',
    name: 'aboutSubscribe',
    component:()=> import('../views/about/aboutSubscribe.vue')//订阅内容
  },
  {
    path: '/3D/3DDetail',
    name: '3DDetail',
    component:()=> import('../views/video/videoDetail.vue')//video详情
  },
  {
    path: '/service/serviceDetail',
    name: 'serviceDetail',
    component:()=> import('../views/service/serviceDetail.vue')//服务范围详情
  },
  {
    path: '/about/aboutTeamDetail',
    name: 'aboutTeamDetail',
    component:()=> import('../views/about/aboutTeamDetail.vue')//团队成员详情
  },
  {
    path: '/search/search',
    name: 'search',
    component:()=> import('../views/search/search.vue')//搜索
  },
  {
    path: '/journal/journalDetail',
    name: 'journalDetail',
    component:()=> import('../views/journal/journalDetail.vue')//咨询中心详情
  },
  {
    path: '/copyright/copyright',
    name: 'copyright',
    component:()=> import('../views/copyright/copyright.vue')//版权声明
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
